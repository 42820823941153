<template>
  <div
    class="flex rounded bg-white h-13 shadow"
    :data-cy="model.title"
    :class="{
      'cursor-pointer': isDraggable,
      'cursor-not-allowed': !isDraggable,
      'z-100': isDragging
    }"
    :draggable="isDraggable"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <div
      class="flex items-center justify-center p-3 rounded-l"
      :class="{
        'handle-blue': isDragging
      }"
    >
      <icon-base
        icon="drag-handle"
        width="12"
        height="20"
        :iconColor="handleIconColor"
      />
    </div>
    <div class="flex items-center border-l pl-4">
      <icon-base
        :icon="icon"
        :class="{ 'text-warning': icon === 'warning' }"
        width="16"
        height="16"
      />
      <lf-h4 class="whitespace-nowrap ml-4 mr-2 pt-1">
        {{ model.title }}
      </lf-h4>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

import {
  DO_DRAGGABLE_DEFAULT,
  DO_TYPE_CONDITIONAL,
  ICON_FOR_CONDITION,
  UTIL_COLORS
} from "@/helpers/constants";
import type { IConditionalArgument, IStep } from "@/models/orchestration";
import { useDoActiveTemplateStatus } from "@/hooks/orchestration";
import { ORCHESTRATION_DRAG_VAL_CHANGED } from "@/helpers/constants/events";

const props = defineProps({
  model: {
    type: Object as PropType<IConditionalArgument>,
    default: null
  }
});

const emitter =
  getCurrentInstance()?.appContext.config.globalProperties.emitter;

const { getters } = useStore();
const { isActiveTemplatePublished } = useDoActiveTemplateStatus();

const isDragging = ref(false);

const icon = computed(() => ICON_FOR_CONDITION[props.model?.id]);

const isDraggable = computed(() => {
  if (isActiveTemplatePublished.value) {
    return false;
  }

  const steps = getters["orchestration/activeTemplateSteps"] as IStep[];
  return !!steps?.length;
});

const handleIconColor = computed(() =>
  isDragging.value ? "white" : UTIL_COLORS.DEFAULT
);

const handleDragStart = (e: DragEvent) => {
  isDragging.value = true;
  e.dataTransfer?.setData("text", JSON.stringify(props.model));
  emitter.emit(ORCHESTRATION_DRAG_VAL_CHANGED, {
    isDragging: true,
    type: DO_TYPE_CONDITIONAL,
    id: props.model.id
  });
};

const handleDragEnd = () => {
  emitter.emit(ORCHESTRATION_DRAG_VAL_CHANGED, DO_DRAGGABLE_DEFAULT);
  isDragging.value = false;
};
</script>

<style scoped>
.handle-blue {
  background-color: #3769d6;
}
</style>
